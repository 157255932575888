<template>
        <div class="profileBack">
                <div class="profileUp">
                        <div class="profileLeft">
                                <div class="titleProd">Фамилия, имя и отчество</div>
                                <input type="text" placeholder="Ваша фамилия" class="inputProfile mt20" v-model="fename" :class="{error : errfield.indexOf('fename') != -1}" @focus="start"/>
                                <input type="text" placeholder="Ваше имя" class="inputProfile mt20" v-model="name" :class="{error : errfield.indexOf('name') != -1}" @focus="start"/>
                                <input type="text" placeholder="Ваше отчество" class="inputProfile mt20" v-model="oth" @focus="start"/>
                                <div class="titleProd mt20">Электронная почта</div>
                                <input type="text" class="mt20" v-model="email" disabled style="width: 100%"/>
                        </div>
                        <div class="profileRight">
                                <div class="titleProd">Роль</div>
                                <Dropdown v-model="prof" :options="roles" optionLabel="name" optionValue="id" class="ddProfile mt20" />
                                <div class="titleProd mt20">Пароль</div>
                                <inputPassword placeholder="Текущий пароль" ref="psold" class="inputProfile mt20" :class="{error:errorCode === 'currentPassword'}" @focus="start"></inputPassword>
                                <inputPassword placeholder="Новый пароль" ref="ps" class="inputProfile mt20" :class="{error:errorCode === 'litlePassword'}" @focus="start"></inputPassword>
                                <inputPassword placeholder="Подтвердить новый пароль" ref="ps1" class="inputProfile mt20" :class="{error:errorCode === 'bothPassword'}" @focus="start"></inputPassword>
                        </div>
                </div>

                <div class="flexRow mt40" style="flex-shrink:0">
                        <buttonRed class="btn" text='Сохранить' @clickButton="save" :disable="isDisable" v-if="isSaved"></buttonRed>
                        <buttonGray class="btn ml10" text='Отменить' @clickButton="close"></buttonGray>
                        <div class="dvError" :class="{viewError : errorText, viewSuccess : saveOk}">{{errorText}}</div>
                </div>

                        <!--div style="width:50%; margin-left: 20px;" >
                                <div class="titleProd mt30">Введите ключ доступа</div>
                                <input type="text" class="mt20" v-model="access_key" style="width: 100%"/>

                                <div class="flexRow mt40" style="flex-shrink:0">
                                        <buttonRed class="btn" text='Отправить' @clickButton="doAccessKey" :disable="isDisableAK"></buttonRed>
                                </div>
                        </div-->
        </div>
</template>

<script>
        import ButtonRed from "../common/ButtonRed"
        import ButtonGray from "../common/ButtonGray"
        import InputPassword from "../common/InputPassword"
        import g from "../globals"
        import Dropdown from 'primevue/dropdown'

        export default {
                components : {ButtonRed, ButtonGray, InputPassword},

                data : function() {
                        return {
                                userData : {},
                                name : "",
                                fename : "",
                                oth : "",
                                email : "",
                                prof : 0,
                                roles : [
                                        {id: 0, name: "Ученик"},
                                        {id: 1, name: "Учитель"},
                                        {id: 2, name: "Родитель"}
                                ],
//                                access_key : "",
                                errfield : [],
                                errorText : null,
                                errorCode : "",
                                saveOk : false,
                                saving : false
                        }
                },

                created() {
                        this.checkPSExist();
                },

                computed : {
/*                        isDisableAK() {
                                return !this.access_key.trim()
                        },*/

                        isDisable() {
                                if(this.saving === true) return true;
                                if(this.name.trim() !== this.userData.name) return false;
                                if(this.fename.trim() !== this.userData.family) return false;
                                if(this.oth.trim() !== this.userData.oth) return false;

                                if(this.$refs.psold && this.$refs.psold.getPS().trim() !== "") return false;
                                if(this.$refs.ps && this.$refs.ps.getPS().trim() !== "") return false;
                                if(this.$refs.ps1 && this.$refs.ps1.getPS().trim() !== "") return false;
                                if(this.prof !== this.userData.prof) return false;

                                return true;
                        },

                        isSaved() {
                                return this.email !== "from_mesh"
                        }
                },

                methods : {
                        checkPSExist() {
                                this.$nextTick(() => {
                                        if(!this.$refs.psold) {
                                                this.checkPSExist();
                                        }
                                        else {
                                                try {
                                                        this.userData = JSON.parse(profile);
                                                } catch (e) {
                                                        this.userData = {"id":9,"family":"Козлов","name":"Андрей","email":"dushik2012@yandex.ru","oth":"Дмитриевич","prof":0}
                                                }

                                                this.name = this.userData.name;
                                                this.fename = this.userData.family;
                                                this.oth = this.userData.oth || "";
                                                this.prof = this.userData.prof || 0;
                                                this.email = this.userData.email;
                                      }
                                })
                        },

                        async save() {
                                this.saving = true;
                                this.saveOk = false;
                                this.errorText = null;
                                this.errfield = [];
                                this.name = this.name.trim();
                                this.fename = this.fename.trim();
                                if(this.name.trim() === "" || this.fename.trim() === "") {
                                        this.errorText = "Поля Имя и Фамилия необходимо заполнить";
                                        if(this.name.trim() === "") this.errfield.push("name");
                                        if(this.fename.trim() === "") this.errfield.push("fename");
                                        this.saving = false;
                                        return;
                                }

                                let param = {
                                        id : this.userData.id,
                                        name : this.name,
                                        family : this.fename,
                                        oth : this.oth,
                                        prof : this.prof,
                                        psOld : this.$refs.psold.getPS(),
                                        ps : this.$refs.ps.getPS(),
                                        ps1 : this.$refs.ps1.getPS()
                                }

                                try {
                                        let data = await g.postUrl("auth/saveData", param);
                                        this.saveOk = true;
                                        this.errorText = "Данные обновлены";
                                        this.userData = param;
                                        this.$refs.psold.clear();
                                        this.$refs.ps.clear();
                                        this.$refs.ps1.clear();
                                } catch (e) {
                                        if(!e) {
                                                e = {"message" : "currentPassword"}
                                        }
                                        this.errorCode = e.message
                                        switch(e.message) {
                                                case "currentPassword":
                                                        this.errorText = "Введен некорректный текущий пароль";
                                                        break;
                                                case "bothPassword":
                                                        this.errorText = "Подтвержденный пароль не совпадает с новым";
                                                        break;
                                                case "litlePassword":
                                                        this.errorText = "Пароль содержит менее 8 символов";
                                                        break;

                                                default: this.errorText = e.message;
                                        }
                                }
                                this.saving = false;
                        },

                        start() {
                                this.errorCode = ""
                                this.errorText = ""
                                this.saveOk = false;
                        },

                        close() {
                                if(window.parent && window.parent.closeProfile) {
                                        window.parent.closeProfile();
                                }
                        },

/*                        async doAccessKey() {
                                let data = await g.postUrl("auth/applyAccessKey", {access_key : this.access_key});
                        }*/
                }
        }

</script>
